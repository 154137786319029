export const baseValidate = (value, message, slashRangeRegex, rangeRegex, basicRegex) => {
    const baseMessage = `Value is not valid (${message} or list of numbers or ranges separated by commas).`
    if (/\s/g.test(value)) {
        return "No whitespace allowed!"
    }

    for (let x of value.split(',')) {
        if (slashRangeRegex.test(x)) {
            x = x.split('/')[0].split('-')
            if (Number(x[0]) >= Number(x[1])) {
                return baseMessage + ' Range invalid.'
            }
        } else if (rangeRegex.test(x)) {
            x = x.split("-")
            if (Number(x[0]) >= Number(x[1])) {
                return baseMessage + ' Range invalid.'
            }
        } else if (!basicRegex.test(x)) {
            return baseMessage
        }
    }
    return undefined;
};

export const validateMinute = (value) =>
        baseValidate(value, '*, 0-59, */0-59',
                    // /^((\*|([0-5]?\d)|(([0-5]?\d)-([0-5]?\d)))\/([0-5]?\d))$/,
                    /^((\*|(([0-5]?\d)-([0-5]?\d)))\/([0-5]?\d))$/,
                    /^([0-5]?\d)-([0-5]?\d)$/,
                    /^(\*|([0-5]?\d))$/)

export const validateHour = (value) =>
        baseValidate(value, '*, 0-23, */0-23',
                    // /^((\*|([0-1]?\d|2[0-3])|(([0-1]?\d|2[0-3])-([0-1]?\d|2[0-3])))\/(([0-1]?\d|2[0-3])))$/,
                    /^((\*|(([0-1]?\d|2[0-3])-([0-1]?\d|2[0-3])))\/(([0-1]?\d|2[0-3])))$/,
                    /^(([0-1]?\d|2[0-3])-([0-1]?\d|2[0-3]))$/,
                    /^(\*|([0-1]?\d|2[0-3]))$/)

export const validateDayOfMonth = (value) =>
        baseValidate(value, '*, 1-31, */1-31',
                    // /^(\*|([1-9]|[1-2]?\d|3[0-1])|(([1-9]|[1-2]?\d|3[0-1])-([1-9]|[1-2]?\d|3[0-1])))\/(([1-9]|[1-2]?\d|3[0-1]))$/,
                    /^(\*|(([1-9]|[1-2]?\d|3[0-1])-([1-9]|[1-2]?\d|3[0-1])))\/(([1-9]|[1-2]?\d|3[0-1]))$/,
                    /^(([1-9]|[1-2]?\d|3[0-1])-([1-9]|[1-2]?\d|3[0-1]))$/,
                    /^(\*|([1-9]|[1-2]?\d|3[0-1]))$/)

export const validateDayOfWeek = (value) =>
        baseValidate(value, '*, 1-7, */1-7',
                    // /^((\*|[1-7]|([1-7]-[1-7]))\/[1-7])$/,
                    /^((\*|([1-7]-[1-7]))\/[1-7])$/,
                    /^([1-7]-[1-7])$/,
                    /^(\*|[1-7])$/)

export const validateMonth = (value) =>
        baseValidate(value, '*, 1-12, */1-12',
                    // /^((\*|[1-9]|1[0-2]|(([1-9]|1[0-2])-([1-9]|1[0-2])))\/(([1-9]|1[0-2])))$/,
                    /^((\*|(([1-9]|1[0-2])-([1-9]|1[0-2])))\/(([1-9]|1[0-2])))$/,
                    /^(([1-9]]|1[0-2])-([1-9]|1[0-2]))$/,
                    /^(\*|[1-9]|1[0-2])$/)