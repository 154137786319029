import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useForm, useFieldArray } from 'react-hook-form';
import { Form, Icon, Header, Label } from 'semantic-ui-react';
import NMService from '../../services/nm.service';
import { parseObject, validateRuleValue } from './utils/utils';
import { useGetGlobalPermission } from '../../hooks/useGetGlobalPermission';
import { NODE_ADMIN } from '../../constants/layout';
import { PermissionsGateV } from '../../layouts/PermissionGate/PermissionGateV';
import { useFormFields } from '../../hooks/useFormFields';
import NMFieldValidator from '../../utils/NMFieldValidator';
import { useFetchLocations } from '../../hooks/useFetchLocations';
import { useFetchSwModules } from '../../hooks/useFetchSwModules';
import { useFetchNodes } from '../../hooks/useFetchNodes';
import { useFetchSwModuleDetails } from '../../hooks/useFetchSwModuleDetails';


export default function AlarmForm(props) {
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);
    const type = props.type;
    const defaultValues = props.defaultValues;

    const {
        handleSubmit,
        register,
        unregister,
        setValue,
        setError,
        clearErrors,
        watch,
        control,
        getValues,
        reset,
        formState: { errors },
    } = useForm({ defaultValues: defaultValues });
    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const { fields, remove, append } = useFieldArray({ control, name: 'rule' });
    const moduleWatch = watch('module')?.split('-')[0];
    const versionWatch = type === 'ext-add' ? watch('version') : watch('module')?.split('-')[1];
    const scopeWatch = watch('scope');
    const hwidWatch = watch('hwid');

    const alarmScopeList = ['none', 'module', 'location', 'tag', 'hwid', 'instance'].map((e) => ({ key: e, value: e, text: e }));
    const {
        data: severityList = [],
    } = useQuery({
        queryKey: ['NMService', 'getAlarmSeverityList'],
        queryFn: () => NMService.getAlarmSeverityList()
                                .then(r => r.data)
                                .catch(e => []),
        select: (e) => e.map((e) => ({
                                        key: String(e.severity),
                                        value: e.severity,
                                        text: `${e.description} (${e.severity})`,
                                    }))
    });

    const {
        data: nodeList = []
    } = useFetchNodes({})

    const nodeid = nodeList && hwidWatch ? ((nodeList || [])?.find((e) => e.hwid === hwidWatch)?.id) : undefined;

    const {
        data: nodeModulesAll = [],
    } = useQuery({
        queryKey: ['getAllNodeModules'],
        queryFn: () => NMService.getAllNodeModules()
                                .then(r => r.data)
                                .catch(e => []),
        enabled: scopeWatch === 'instance',
    });

    const {
        data: swModuleList = []
    } = useFetchSwModules({
        select: (e) => e.map(({ id, version }) => ({
                                                      key: `${id}-${version}`,
                                                      value: `${id}-${version}`,
                                                      text: `${id} (${version})`,
                                                  }))
    })

    const {
        data: locationList = []
    } = useFetchLocations({
        select: (e) => e.map((e) => ({ key: e.id, value: e.id, text: e.name }))
    })

    const {
        data: tagList = [],
    } = useQuery({
        queryKey: ['NMService', 'getNodeTags'],
        queryFn: () => NMService.getNodeTags()
                                .then(r => r.data)
                                .catch(e => []),
        select: (e) => e.map((e) => ({ key: e.tag, value: e.tag, text: e.tag }))
    });

    const {
        data: variableList = [],
    } = useFetchSwModuleDetails({
        moduleid: moduleWatch,
        version: versionWatch,
        enabled: Boolean(moduleWatch) && Boolean(versionWatch),
        select: e => [].concat(parseObject(JSON.parse(e?.resultmodel), '', 0, null, '$'))
    })

    const getVariableOptions = () => (!variableList || !Array.isArray(variableList)) ? [] : variableList.map((e) => ({ key: e.name, value: e.path, text: e.description }))

    const getOperOptions = (path) => (!variableList || !Array.isArray(variableList) || !path) ? []
            : ((variableList || [])?.find((e) => e.path === path)?.operators.map((e) => ({ key: e, value: e, text: e }))) || [];

    const getHwidOptions = () => {
        if (!nodeList || !Array.isArray(nodeList)) return [];
        const nodeIdList = nodeModulesAll.filter(e => e.moduleid === moduleWatch && e.version === versionWatch)
                                         .map(e => e.nodeid)
        return nodeList.filter(e => nodeIdList.includes(e.id))
                        .map(e => ({
                                key: e.hwid,
                                value: e.hwid,
                                text: `${e.name}${e.locationdata?.name && " (" + e.locationdata.name + ")"}`
                            }))
    };

    const getInstanceOptions = () =>
        (!nodeModulesAll || !Array.isArray(nodeModulesAll)) ? [] 
        : nodeModulesAll.filter(e => e.nodeid === nodeid && e.moduleid === moduleWatch && e.version === versionWatch)
                        .map(e => ({ key: e.instanceid, value: e.instanceid, text: e.instanceid }))

    const onSubmit = (values) => {
        let data = { ...values };
        unregister('rule[0].boolOp'); 

        if (data.rule.length === 0) {
            setError('ruleMinLength', { type: 'manual', message: 'At least one rule is required' });
            return;
        }

        let ruleDefinition = '';
        for (const rule of values.rule) {
            const { boolOp, variable, oper, value } = rule;
            if (boolOp) { ruleDefinition += ` ${boolOp} ` }

            const type = (variableList || [])?.find((e) => e.path === variable)?.type;
            if (type === 'string') {
                ruleDefinition += `${variable} ${oper} "${value.trim()}"`;
            } else {
                ruleDefinition += `${variable} ${oper} ${value.trim()}`;
            }
        }
        data.rule = ruleDefinition;
        data.module = moduleWatch
        data.version = versionWatch

        switch (data.scope) {
            case 'tag':
                delete data.location;
                delete data.hwid;
                delete data.instanceid;
                break;
            case 'location':
                delete data.tag;
                delete data.hwid;
                delete data.instanceid;
                break;
            case 'module':
                delete data.tag;
                delete data.location;
                delete data.hwid;
                delete data.instanceid;
                break;
            case 'instance':
                delete data.tag;
                delete data.location;
                break;
            case 'hwid':
                delete data.tag;
                delete data.location;
                delete data.instanceid;
                break;
            default: // none
                delete data.tag;
                delete data.location;
                delete data.hwid;
                delete data.instanceid;
                break;
        }
        props.submitForm(data)
    };

    useEffect(() => {
        reset(defaultValues);
        return () => reset();
    }, [defaultValues]); //eslint-disable-line

    return (
        <>
            <Header as="h4" dividing content={type === 'add' || type === 'ext-add' ? 'Add alarm' : 'Edit alarm'}/>
            <Form onSubmit={handleSubmit(onSubmit)} className="basic segment" style={{ marginLeft: '1rem' }}>
                {type === 'add' &&
                    <Form.Group>
                        <Form.Field width={2}>Module</Form.Field>
                        {renderDropdown('Module', 'module', swModuleList, { onChange: remove, hideLabel: true, width: 4 })}
                    </Form.Group>}
                {type !== 'ext-edit' && type !== 'ext-add' && 
                    <Form.Group>
                        <Form.Field width={2}>Scope</Form.Field>
                        {renderDropdown('Scope', 'scope', alarmScopeList, { hideLabel: true, width: 4, disabled: type === 'ext-edit' })}
                    </Form.Group>}
                {scopeWatch === 'location' &&
                    <Form.Group>
                        <Form.Field width={2}>Location</Form.Field>
                        {renderDropdown('Location', 'location.id', locationList, { hideLabel: true, width: 4, disabled: type === 'ext-edit' })}
                    </Form.Group>}
                {scopeWatch === 'tag' &&
                    <Form.Group>
                        <Form.Field width={2}>Tag</Form.Field>
                        {renderDropdown('Tag', 'tag', tagList, { hideLabel: true, width: 4, disabled: type === 'ext-edit' })}
                    </Form.Group>}
                {!['ext-edit', 'ext-add'].includes(type) && (scopeWatch === 'hwid' || scopeWatch === 'instance') &&
                    <Form.Group>
                        <Form.Field width={2}>HW ID</Form.Field>
                        {renderDropdown('HWID', 'hwid', getHwidOptions(), { hideLabel: true, width: 4, disabled: type === 'ext-edit' })}
                    </Form.Group>}
                {!['ext-edit', 'ext-add'].includes(type) && hwidWatch && scopeWatch === 'instance' &&
                    <Form.Group>
                        <Form.Field width={2}>Instance</Form.Field>
                        {renderDropdown('Instance', 'instanceid', getInstanceOptions(), { hideLabel: true, width: 4, disabled: type === 'ext-edit' })}
                    </Form.Group>}
                <Form.Group>
                    <Form.Field width={2}>
                        Rule definition
                        <Icon
                            name="plus"
                            link
                            title="Add rule condition"
                            style={{ cursor: 'pointer', marginLeft: '1rem', color: '#4183c4' }}
                            onClick={(e) => {
                                e.preventDefault();
                                append({ boolOp: undefined, variable: undefined, oper: undefined, value: undefined });
                                clearErrors('ruleMinLength');
                            }}
                        />
                    </Form.Field>
                    {errors.ruleMinLength?.type === 'manual' && (
                        <Label basic color="red" pointing="left" content={errors.ruleMinLength.message}/>
                    )}
                </Form.Group>
                {fields.map((field, index) => (
                    <Form.Group key={index}>
                        {index > 0 ?
                            renderDropdown('', `rule.${index}.boolOp`, [{ key: 'and', value: 'and', text: 'and' }, { key: 'or', value: 'or', text: 'or' }], { noPlaceholder: true, hideLabel: true, width: 2 })
                            : <Form.Field width={2}></Form.Field>}
                        {renderDropdown('Variable', `rule.${index}.variable`, getVariableOptions(), { width: 6, hideLabel: true })}
                        {renderDropdown('', `rule.${index}.oper`, getOperOptions(watch(`rule.${index}.variable`)), { noPlaceholder: true, width: 2, hideLabel: true })}
                        {renderInput('Value', `rule.${index}.value`, { width: 4, hideLabel: true, validate: (value) => validateRuleValue(value, getValues(`rule.${index}.variable`), variableList) })}
                        <Form.Field style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon name='close' link color='red' size='large' onClick={() => remove(index)}/>
                        </Form.Field>
                    </Form.Group>
                ))}
                <Form.Group>
                    <Form.Field width={2}>Custom message</Form.Field>
                    {renderInput('Message', 'message', { validate: (v) => NMFieldValidator.validateString(v, undefined, 3, 127, true), hideLabel: true, width: 4 })}
                </Form.Group>
                <Form.Group>
                    <Form.Field width={2}>Interval</Form.Field>
                    {renderInput('Interval', 'interval', { hideLabel: true, inputType: 'number', unit: 's', min: 1, max: 86400, width: 4})}
                </Form.Group>
                <Form.Group>
                    <Form.Field width={2}>Count</Form.Field>
                    {renderInput('Count', 'count', { hideLabel: true, inputType: 'number', min: 1, max: 256, width: 4 })}
                </Form.Group>
                <Form.Group>
                    <Form.Field width={2}>Severity</Form.Field>
                    {renderDropdown('Severity', 'severity', severityList, { hideLabel: true, width: 4 })}
                </Form.Group>
                <Form.Group>
                    <Form.Field width={2}></Form.Field>
                    <PermissionsGateV hasPermission={hasPermission}>
                        <Form.Button type="submit" size="small" primary content={type === 'add' ? 'Add' : 'Update'}/>
                    </PermissionsGateV>
                    <PermissionsGateV hasPermission={hasPermission}>
                        <Form.Button type="button" size="small" content='Cancel' onClick={() => props.toggleForm()} />
                    </PermissionsGateV>
                </Form.Group>
            </Form>
        </>
    );
}
