import React, { Fragment, useCallback, useEffect } from 'react';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { Breadcrumb, Divider, Form, Header, Icon } from 'semantic-ui-react';
import NMService from '../../services/nm.service';
import { customDhcpV4Options } from './utils/custom-dhcpv4-options';
import LoggerOutputOption from './LoggerOutputOption';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import { useSetAtom } from 'jotai';
import { useFormFields } from '../../hooks/useFormFields';

export default function DhcpGlobalConfiguration(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const methods = useForm();
    const {
        handleSubmit,
        register,
        setValue,
        control,
        reset,
        watch,
        formState: { errors },
    } = methods;
    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const {
        fields: intfFields,
        remove: removeIntf,
        append: appendIntf,
    } = useFieldArray({ control, name: 'interfaces-config.interfaces' });

    const {
        fields: loggerFields,
        remove: removeLogger,
        append: appendLogger,
    } = useFieldArray({ control, name: 'loggers' });

    const {
        fields: optionFields,
        remove: removeOption,
        append: appendOption,
    } = useFieldArray({ control, name: 'option-data' });

    const getOptionDataOptions = () => {
        return customDhcpV4Options.map((option) => ({
            key: `${option.name}_${option.code}`,
            value: option.code,
            text: `${option.name} ${option.code}`,
        }));
    };

    const fetchDhcpGeneralConfiguration = useCallback(() => {
        NMService.getDhcpGeneralConfiguration().then((response) => {
            let generalConfig = {};
            if (response?.data) {
                Object.entries(response.data).forEach(([key, value]) => {
                    generalConfig[key] = JSON.parse(value);
                    if (key === 'interfaces-config') {
                        generalConfig['interfaces-config'].interfaces = generalConfig['interfaces-config'].interfaces
                            .map((e) => ({ var: e }))
                            .filter((e) => e);
                    }
                });
            }
            reset(generalConfig);
        }).catch(e => null)
    }, [reset]);

    const onSubmit = (values) => {
        const data = {
            ...values,
            'interfaces-config': {
                'dhcp-socket-type': values['interfaces-config']['dhcp-socket-type'],
                interfaces: values['interfaces-config']['interfaces'].map((e) => e.var).filter((e) => e),
            },
        };

        NMService.updateDhcpGeneralConfiguration(data).then(() =>
            addGlobalMessage({
                header: 'DHCP general configuration update',
                content: 'DHCP general configuration was successfully updated',
                type: 'positive',
            })).catch(e => null)
    };

    useEffect(() => {
        fetchDhcpGeneralConfiguration();
    }, [fetchDhcpGeneralConfiguration]);

    return (
        <>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Configuration</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section>DHCP</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active>Global configuration</Breadcrumb.Section>
            </Breadcrumb>
            <Divider />
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)} className="basic segment" style={{ padding: '0 0' }}>
                    <Header dividing as={'h4'} style={{ margin: '0.5rem 0 1.5rem 0' }}>Interfaces</Header>
                    {renderDropdown('DHCP socket type', 'interfaces-config.dhcp-socket-type', [{ key: 'udp', value: 'udp', text: 'UDP' }], { width: 3 })}
                    <label style={{ paddingLeft: '0.5rem', paddingTop: '1rem' }}>Add new interface</label>
                    <Icon
                        name="plus"
                        link
                        style={{ marginLeft: '1rem', paddingTop: '1rem', color: '#4183c4' }}
                        title="Add new interface"
                        onClick={() => appendIntf({ var: undefined})}
                    />
                    {intfFields.map((intfField, intfIndex) => (
                        <Form.Group key={intfField.id} style={{ margin: '1rem 0 0 3rem' }}>
                            <Icon
                                link
                                name="trash alternate"
                                style={{ color: '#4183C4', position: 'relative', marginTop: '0.5rem' }}
                                onClick={() => removeIntf(intfIndex)}
                            />
                            {renderInput('Interface', `interfaces-config.interfaces.${intfIndex}.var`, { hideLabel: true, width: 4 })}
                        </Form.Group>
                    ))}
                    <Header dividing as={'h4'} style={{ margin: '2rem 0 1.5rem 0' }}>Control socket</Header>
                    <Form.Group>
                        {renderDropdown('Socket type', 'control-socket.socket-type', [{ key: 'unix', value: 'unix', text: 'Unix' }], { width: 3 })}
                        {renderInput('Socket name', 'control-socket.socket-name', { notRequired: true, width: 4 })}
                    </Form.Group>
                    <Header dividing as={'h4'} style={{ margin: '2rem 0 1.5rem 0' }}>Lease database</Header>
                    <Form.Group>
                        {renderDropdown('Type', 'lease-database.type', [{ key: 'memfile', value: 'memfile', text: 'memfile' }], { width: 3 })}
                        {renderInput('LFC interval', 'lease-database.lfc-interval', { inputType: 'number', unit: 'sec', width: 3 })}
                    </Form.Group>
                    <Header dividing as={'h4'} style={{ margin: '2rem 0 1.5rem 0' }}>Loggers</Header>
                    <Form.Group style={{ margin: '1rem 0 0 3rem' }}>
                        <label style={{ paddingLeft: '0.5rem' }}>Add new logger</label>
                        <Icon
                            name="plus"
                            link
                            style={{ margin: '0 0 1.5rem 0.5rem', color: '#4183c4' }}
                            title="Add new logger"
                            onClick={() => appendLogger({ name: undefined, output_options: [], severity: undefined })}
                        />
                    </Form.Group>
                    {loggerFields.map((loggerField, loggerIndex) => (
                        <Fragment key={loggerField.id}>
                            <Form.Group style={{ marginLeft: '7rem' }}>
                                <Icon
                                    link
                                    name="trash alternate"
                                    style={{ color: '#4183C4', position: 'relative', marginTop: '1.8rem'  }}
                                    onClick={() => removeLogger(loggerIndex)}
                                />
                                {renderInput('Name', `loggers.${loggerIndex}.name`, { width: 3 })}
                                {renderDropdown('Severity', `loggers.${loggerIndex}.severity`, [
                                                    { key: 'EMERGENCY', value: 'EMERGENCY', text: 'Emergency (0)' },
                                                    { key: 'ALERT', value: 'ALERT', text: 'Alert (1)' },
                                                    { key: 'CRITICAL', value: 'CRITICAL', text: 'Critical (2)' },
                                                    { key: 'ERROR', value: 'ERROR', text: 'Error (3)' },
                                                    { key: 'WARNING', value: 'WARNING', text: 'Warning (4)' },
                                                    { key: 'NOTICE', value: 'NOTICE', text: 'Notice (5)' },
                                                    { key: 'INFORMATIONAL', value: 'INFORMATIONAL', text: 'Informational (6)' },
                                                    { key: 'DEBUG', value: 'DEBUG', text: 'Debug (7)' },
                                                ], { width: 3 })}
                            </Form.Group>
                            <LoggerOutputOption parentIndex={loggerIndex} renderInput={renderInput}/>
                        </Fragment>
                    ))}
                    <Header dividing as={'h4'} style={{ margin: '2rem 0 1.5rem 0' }}>Expired leases processing</Header>
                    <Form.Group widths={6}>
                        {renderInput('Reclaim timer', 'expired-leases-processing.reclaim-timer-wait-time', { notRequired: true, inputType: 'number', min: 0, unit: 'sec' })}
                        {renderInput('Flush reclaim timer', 'expired-leases-processing.flush-reclaimed-timer-wait-time', { notRequired: true, inputType: 'number', min: 0, unit: 'sec' })}
                        {renderInput('Hold reclaimed time', 'expired-leases-processing.hold-reclaimed-time', { notRequired: true, inputType: 'number', min: 0, unit: 'sec' })}
                        {renderInput('Max reclaim leases', 'expired-leases-processing.max-reclaim-leases', { notRequired: true, inputType: 'number', min: 0 })}
                        {renderInput('Max reclaim time', 'expired-leases-processing.max-reclaim-time', { notRequired: true, inputType: 'number', min: 0, unit: 'ms' })}
                        {renderInput('Unwarned reclaim cycles', 'expired-leases-processing.unwarned-reclaim-cycles', { notRequired: true, inputType: 'number', min: 0 })}
                    </Form.Group>
                    <Header dividing as={'h4'} style={{ margin: '2rem 0 1.5rem 0' }}>Global timer</Header>
                    <Form.Group widths={5}>
                        {renderInput('Valid lifetime', 'renew-timer', { notRequired: true, inputType: 'number', min: 0, unit: 'sec' })}
                        {renderInput('Renew timer', 'rebind-time', { notRequired: true, inputType: 'number', min: 0, unit: 'sec' })}
                        {renderInput('Rebind timer', 'valid-lifetime', { notRequired: true, inputType: 'number', min: 0, unit: 'sec' })}
                    </Form.Group>
                    <Header dividing as={'h4'} style={{ margin: '2rem 0 1.5rem 0' }}>Option data</Header>
                    <Form.Group style={{ margin: '1rem 0 0 3rem' }}>
                        <label style={{ paddingLeft: '0.5rem' }}>Add new option</label>
                        <Icon
                            name="plus"
                            link
                            style={{ margin: '0 0 1.5rem 0.5rem', color: '#4183c4' }}
                            title="Add new option"
                            onClick={() => appendOption({ code: '', data: '' })}
                        />
                    </Form.Group>
                    {optionFields.map((optionField, optionIndex) => (
                        <Form.Group key={optionField.id} style={{ marginLeft: '7rem' }}>
                            <Icon
                                link
                                name="trash alternate"
                                style={{ color: '#4183C4', position: 'relative', marginTop: '1.8rem'  }}
                                onClick={() => removeOption(optionIndex)}
                            />
                            {renderDropdown('Option code/name', `option-data.${optionIndex}.code`, getOptionDataOptions(), { width: 4 })}
                            {renderInput('Data', `option-data.${optionIndex}.data`, { width: 6 })}
                        </Form.Group>
                    ))}
                    <Form.Button style={{ margin: '3rem 0 1rem 0' }} type="submit" size="small" primary content='Update'/>
                </Form>
            </FormProvider>
        </>
    );
}
